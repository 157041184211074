.container {
  // width: 80vw;
  margin: auto;
  @media (min-width: 45rem) {
    width: 40vw;
  }
}

.topbar {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(55, 71, 82, 0.05);

  p {
    padding-left: 15px;
    font-weight: 700;
    font-size: 16px;
    color: #434343;
  }
}
.desclaimer {
  margin: 16px;
  padding: 20px;
  background: #fffaf3;
  border: 1px solid #fec97e;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  p {
    margin-left: 15px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #434343;
  }
}

//Card
.card {
  hr {
    border: 1px solid #ebebeb;
  }
  .cardData {
    display: flex;
    padding: 20px;
  }
  .cardData_img {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 104px;
    min-height: 104px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 12px;
    img {
      height: 80px;
    }
  }
  .cardData_data {
    margin-left: 10px;
    h1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #333333;
    }
    div {
      display: flex;
      margin-top: 5px;
      margin-bottom: 5px;
      p {
        margin-left: 10px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #888888;
      }
      .descriptiontext {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
        p {
          font-weight: 600;
          color: #434343;
          line-height: 16px;
        }
      }
    }
  }
}

// Modal
.modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .paper {
    width: 100vw;
    @media (min-width: 45rem) {
      width: 40vw;
    }
    padding: 20px;
    color: #333333;
    outline: 0px;
    background: #ffffff;
    border-radius: 40px 40px 0px 0px;
    padding: 30px;
    text-align: center;
    img {
      height: 100px;
    }

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.01em;
      color: #434343;
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #434343;
    }
  }
}

.modalbutton {
  background: linear-gradient(180deg, #55c0f9 0%, #1341f5 100%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px !important;
  width: 208px;
  height: 48px;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12) !important;
  text-transform: uppercase;
  color: #ffffff !important;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(180deg, #55c0f9 0%, #1341f5 100%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  .footer_text {
    padding: 10px 0px 5px 0px;
    text-align: center;
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
      color: #ffffff;
      span {
        font-weight: 800;
        font-size: 18px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .footer_logo {
    display: flex;
    justify-content: center;
    margin: 0px;
    img {
      width: 100px;
      height: 30px;
    }
  }
}

.conditions {
  bottom: 0px;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

$rubik: "Rubik", sans-serif;
$nutino: "Nunito Sans", sans-serif;
$primary: #434343;

.container {
  background: blue($color: #000000);
  padding: 22px 16px;
  border-bottom: 1px solid rgba(136, 136, 136, 0.1);
}

.heading {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  img {
    border-radius: 50%;
    height: 78px;
    width: 78px;
  }
  .heading_title {
    margin-top: 5px;
    padding-left: 10px;
    h1 {
      font-family: $nutino;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      color: #374752;
    }
    div {
      display: flex;
      margin-top: 10px;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        align-self: flex-start;
      }
      p {
        padding-left: 10px;
        font-family: $nutino;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        text-transform: lowercase;
        color: rgba(55, 71, 82, 0.7);
        word-wrap: break-word;
        padding-right: 10px;
      }
    }
  }
}

.service_type {
  display: flex;
  padding: 10px 10px 5px 10px;
  align-items: center;
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }
  img {
    width: 20px;
  }
}
.mobile {
  display: flex;
  padding: 10px 10px;
  align-items: center;
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    color: #434343;
  }
}

.pickup {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }
}

.time {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }
}

.days {
  display: flex;
  justify-content: space-between;
  padding: 5px 40px;
  div {
    display: flex;
    border: 1px solid #888888;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $primary;
  }
}

.covidfood {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  img {
    height: 35px;
    width: 35px;
  }
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #434343;
  }
}

.whatsappshare {
  display: flex;
  justify-content: flex-end;
}

#selected {
  background: #437af8;
  color: white;
}

.hr {
  opacity: 0.1;
  border: 1px solid #888888;
}

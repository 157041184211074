$rubik: "Rubik", sans-serif;
$nutino: "Nunito Sans", sans-serif;
$primary: #434343;
.container {
  background-color: white;
  margin: auto;

  @media (min-width: 45rem) {
    width: 40vw;
  }
}

.loadingScreen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bcicon {
  padding: 20px 0px 10px 20px;
  // border-bottom: 1px solid #ebebeb;

  div {
    display: flex;
    justify-content: center;
    img {
    }
  }
}

.lgimg {
  border: 1px solid #ebebeb;
  z-index: 0;
  img {
    width: 100%;
    height: 25vh;
    object-fit: contain;
  }
}

.content {
  padding: 10px 20px;
}

.restrauntname {
  padding: 10px 0px;
  display: flex;
  h1 {
    font-family: $nutino;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #374752;
  }
  img {
    padding-left: 10px;
  }
}

.card {
  background: #dcfff1;
  border: 1px solid #00a361;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  .card_img {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
    }
    padding-right: 10px;
  }
  .card_txt {
    h1 {
      font-family: $nutino;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #434343;
      font-style: normal;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
    p {
      font-family: $nutino;
      margin-top: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #434343;
    }
  }
}

.address {
  display: flex;
  align-items: center;
  padding: 20px 0px 10px 0px;
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-weight: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }
}

.mobile {
  display: flex;
  padding: 10px 0px;
  // img {
  // }
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #434343;
  }
}

.pickup {
  display: flex;
  padding: 10px 0px;
  // img {
  // }
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #434343;
  }
}

.time {
  display: flex;
  padding: 10px 0px;
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #434343;
  }
}

.days {
  display: flex;
  justify-content: space-between;
  padding: 5px 40px;
  div {
    display: flex;
    border: 1px solid #888888;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $primary;
  }
}

.menuImages {
  padding: 20px;
  padding-bottom: 40px;
  h1 {
    font-family: $nutino;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }
  .menuImagesContainer {
    display: flex;
    flex-wrap: wrap;
    img {
      margin-top: 10px;
      margin-right: 10px;
      width: 95px;
      height: 95px;
      background: #fafafa;
      border: 1px dashed #888888;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.service_type {
  display: flex;
  padding: 10px 10px 10px 0px;
  align-items: center;
  p {
    padding-left: 10px;
    font-family: $nutino;
    font-style: normal;
    font-size: 14px;
  }
  img {
    width: 20px;
  }
}
.whatsappshare {
  display: flex;
  justify-content: flex-end;
}

.footer {
  height: 28px;
  width: 100vw;
  background: linear-gradient(180deg, #55c0f9 0%, #1341f5 100%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  position: fixed;
  bottom: 0px;

  @media (min-width: 45rem) {
    width: 40vw;
  }
}

#selected {
  background: #437af8;
  color: white;
}

.circle {
  z-index: 9999;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -35px;
  float: right;
  height: 70px;
  width: 70px;
  border-radius: 35px;
  box-shadow: 0px 4px 12px 0px #0000001f;
}

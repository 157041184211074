.container {
  background-color: white;
  @media (min-width: 45rem) {
    width: 40vw;
  }

  img {
    height: 300px;
    width: 100vw;
    object-fit: contain;
    border-bottom: 1px solid #ebebeb;
  }
}

.bcicon {
  display: flex;
  align-items: center;
  padding: 20px 0px 10px 20px;
  border-bottom: 1px solid #ebebeb;
  h2 {
    color: #434343;
  }
}

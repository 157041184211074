$rubik: "Rubik", sans-serif;
$nutino: "Nunito Sans", sans-serif;
$primary: #434343;

.container {
  margin: auto;
  width: 100vw;
  padding: 0px 10px;
  height: 100vh;

  @media (min-width: 45rem) {
    width: 40vw;
  }

  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

.title {
  padding: 30px 0px 0px 0px;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    font-family: $rubik;
    color: $primary;
  }
}

.description {
  margin-top: 20px;
  p {
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    font-family: $nutino;
  }
}

.mobilenumber {
  display: flex;
  margin-top: 5px;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    font-family: $nutino;
    color: $primary;
  }
  span {
    font-size: 14px;
    margin-left: 20px;
    color: #437af8;
    font-weight: 700;
  }
}
.inputContainer {
  margin-top: 40px;
}

.btnContainer {
  padding: 40px 0px;
  button {
    width: 100%;
    background: linear-gradient(180deg, #55c0f9 0%, #1341f5 100%);
    border-radius: 8px;
    height: 48px;
    font-family: $rubik;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    outline: none;
  }

  button:disabled {
    background: #cccccc;
    font-weight: 800;
  }
}

.otp {
  display: flex;
  justify-content: center;
  p {
    font-family: $nutino;
    color: $primary;
  }
}
.otp2 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  p {
    font-family: $nutino;
    color: $primary;
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
  }
  span {
    font-family: $nutino;
    margin-left: 10px;
    color: #437af8;
    font-weight: 700;
  }
}

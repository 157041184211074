$rubik: "Rubik", sans-serif;
$nutino: "Nunito Sans", sans-serif;
$primary: #434343;
$blue: #1e73b1;

.container {
  margin: auto;
  width: 100vw;
  @media (min-width: 45rem) {
    width: 40vw;
  }
}

.header {
  height: 230px;
  background: #437af8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .imgContainer {
    width: 105px;
    height: 105px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 5px;
  }
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 33px;
    font-family: $rubik;
    color: #ffffff;
  }
}

.content {
  background-color: white;
  position: relative;
  top: -20px;
  border-radius: 22px 22px 0px 0px;
  padding: 0px 15px;
}

.description {
  padding-top: 20px;
  p {
    color: $primary;
    font-family: $nutino;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    font-family: $nutino;
    font-weight: 600;
  }
}

.inputContainer {
  h2 {
    color: $blue;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    font-family: $nutino;
    margin-top: 20px;
  }
  .scheduling {
    margin-top: 20px;
    display: flex;
  }
  .schedulingbtns {
    display: flex;
    margin-top: 10px;
  }
  .checkboxGroup {
    margin-top: 20px;
    margin-bottom: 15px;
    h1 {
      font-family: $nutino;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .radioGroup {
    margin-top: 10px;
    h1 {
      font-family: $nutino;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .menuImages {
    margin-top: 10px;
    h1 {
      font-family: $nutino;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
    }
    .menuImagesContainer {
      display: flex;
      flex-wrap: wrap;
      label {
        margin-top: 10px;
        margin-right: 10px;
        width: 95px;
        height: 95px;
        background: #fafafa;
        border: 1px dashed #888888;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input[type="file"] {
        display: none;
      }
    }
  }
}

.btnContainer {
  padding: 30px 0px;
  button {
    width: 100%;
    background: #437af8;
    border-radius: 8px;
    height: 48px;
    font-family: $rubik;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    outline: none;
  }
}

$rubik: "Rubik", sans-serif;
$nutino: "Nunito Sans", sans-serif;
$primary: #434343;

.container {
  margin: auto;
  background: #f2f7ff;
  background-repeat: no-repeat;
  background-position: top;
  @media (min-width: 45rem) {
    width: 40vw;
  }
}

.header {
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 30px;
  }
  .register {
    background: linear-gradient(180deg, #f27528 0%, #e09b4a 100%);
    text-transform: none;
    padding: 4px 21px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
    border-radius: 16px;
    color: white;
  }

  .switch {
    background: #437af8;
    text-transform: none;
    padding: 4px 21px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.12);
    border-radius: 16px;
    color: white;
  }
  .searchbtn {
    box-shadow: 0px 4px 12px rgba(51, 51, 51, 0.12);
    margin-top: 30px;
    display: flex;
    background-color: white;
    border-radius: 50px;
    height: 44px;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    p {
      font-family: $nutino;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #434343;
    }
  }
}

.bg {
  height: 295px;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    height: 260px;
    width: 94vw;
    border-radius: 8px;
    padding: 13px 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    p {
      color: #434343;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #434343;
    }

    .search {
      background: linear-gradient(180deg, #55c0f9 0%, #1341f5 100%);
      padding: 11px 46px;
      border-radius: 8px;
      font-weight: 700;
      color: white;
    }
  }
}

.illusion {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.static {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 70px 0 50px 0;
  padding-top: 20px;
  h1 {
    font-size: 34px;
    font-weight: 800;
    color: #434343;
    text-align: center;
  }

  h2 {
    margin-left: 16px;
    align-self: flex-start;
    width: 70vw;
    font-size: 48px;
    font-weight: 800;
    color: #e5e5e5;
  }

  .points {
    margin-top: 31px;

    .pointscontainer {
      display: flex;
      align-items: center;
      padding: 0px;
      margin-top: 18px;

      p {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 700;
        color: #434343;
      }
    }
  }
}

.cardContainer {
  background-color: white;
  .cardContainerTitle {
    padding: 20px 0px;
    border-bottom: 1px solid rgba(136, 136, 136, 0.1);
    h1 {
      font-family: $nutino;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 27px;
      text-align: center;
      color: #888888;
    }
    h2 {
      font-family: $nutino;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 19px;
      color: #888888;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(180deg, #55c0f9 0%, #1341f5 100%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  .footer_text {
    padding: 10px 0px 5px 0px;
    text-align: center;
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
      color: #ffffff;
      span {
        font-weight: 800;
        font-size: 18px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .footer_logo {
    display: flex;
    justify-content: center;
    margin: 0px;
    img {
      width: 100px;
      height: 30px;
    }
  }
}

.conditions {
  bottom: 0px;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.desclaimer {
  background-repeat: no-repeat;
  margin: 16px;
  padding: 20px;
  background: #fffaf3;
  border: 1px solid #fec97e;
  box-sizing: border-box;
  border-radius: 0px 12px 12px 12px;
  div {
    display: flex;
    align-items: center;
    p {
      margin-left: 15px;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #434343;
    }
  }
  .dec_button {
    border-radius: 80px !important;
    background: linear-gradient(0deg, #04c969, #04c969);
    color: white !important;
    font-weight: 600 !important;
  }
}

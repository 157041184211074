$rubik: "Rubik", sans-serif;
$nutino: "Nunito Sans", sans-serif;
$primary: #434343;
$blue: #1e73b1;

.container {
  margin: auto;
  width: 100vw;
  height: 100vh;
  padding-bottom: 40px;
  overflow-y: auto;
  @media (min-width: 45rem) {
    width: 40vw;
  }
}

.header {
  height: 290px;
  background: #437af8;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0 20px 0;

  .imgContainer {
    background-color: white;
    padding: 10px;
    border-radius: 14px;
  }
  .customerButton,
  .customerButton:hover {
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 12px rgba(51, 51, 51, 0.12);
    padding: 6px 21px;
    color: #434343;
    font-weight: 700;
    font-family: $nutino;
    font-size: 16px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p {
    text-align: center;
  }
  h1 {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    font-family: $rubik;
    color: #ffffff;
  }
}

.content {
  background-color: white;
  position: relative;
  top: -20px;
  padding: 10px 15px;
  border-radius: 22px 22px 0px 0px;
}

.subtitle {
  margin-top: 30px;
  h2 {
    color: $blue;
    font-family: $nutino;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }
}

.details {
  .detailsContainer {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
    margin: 10px 0;
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22p;
      font-family: $nutino;
      color: #888888;
    }
    h2 {
      color: $primary;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.timedetails {
  .timedetailsFlex {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
    margin: 10px 0;
    .timedetailsContainer {
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22p;
        font-family: $nutino;
        color: #888888;
      }
      h2 {
        color: $primary;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}
.menuImages {
  margin-top: 10px;
  h1 {
    font-family: $nutino;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #888888;
  }
  .menuImagesContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    img {
      margin-right: 10px;
      width: 95px;
      height: 95px;
      border: 1px solid #888888;
      border-radius: 8px;
      margin-top: 10px;
    }
    div {
      margin-right: 10px;
      width: 78px;
      height: 78px;
      background: #fafafa;
      border: 1px dashed #888888;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}
.btnContainer {
  padding: 40px 0px 35px 0px;
  button {
    width: 100%;
    background: white;
    border-radius: 8px;
    height: 48px;
    font-family: $rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: $blue;
    border: 1px solid;
    outline: none;
  }
}

.sharecontainer {
  display: flex;
  justify-content: center;
  .share {
    width: 240px;
    position: fixed;
    display: flex;
    justify-content: space-around;
    align-content: center;
    bottom: 10px;
    padding: 12px 24px;
    border-radius: 50px;
    background-color: #04c969;
    box-shadow: 0px 4px 12px rgba(51, 51, 51, 0.12);
    color: white;
    align-self: center;
    font-weight: 700;
    font-size: 15px;
    z-index: 1;
  }
  .share:hover {
    position: fixed;
    bottom: 10px;
    padding: 12px 24px;
    border-radius: 16px;
    background-color: #00e676;
    box-shadow: 0px 4px 12px 0px #3333331f;
    color: white;
    font-weight: 700;
    align-self: center;
  }
}

.switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22p;
    font-family: $nutino;
    color: #888888;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(180deg, #55c0f9 0%, #1341f5 100%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  .footer_text {
    padding: 10px 0px 5px 0px;
    text-align: center;
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
      color: #ffffff;
      span {
        font-weight: 800;
        font-size: 18px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .footer_logo {
    display: flex;
    justify-content: center;
    margin: 0px;
    img {
      width: 100px;
      height: 30px;
    }
  }
}

.conditions {
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
